<script>
import {
  DIALOG_TITLE_TYPE,
  UPLOAD_IMG_TYPE,
  getValue,
  INTLLIGENT_STATUS,
  INTLLIGENT_CONTROL_PLATFORM,
  SUBDEVICE_CATEGORY_TYPE,
  SUBDEVICE_CATEGORY_TYPE_IMG,
  IS_ONLINE
} from "@/utils/enumUtile";
import { formatApiDate, getFileName } from "@/utils/utils";
import {
  getDeviceList,
  createDevice,
  getEquipmentList,
  goOnlineDevice,
  getWxQrcode,
  synchDevices,
  getSubList,
  addSub,
  deleteSub
} from "@/api/equipment";
import { getStoreList } from "@/api/store";
import UploadList from "@/views/components/uploadList.vue";
import CascadeRegion from "@/views/components/cascadeRegion.vue";
import EquipmentServiceDialog from "./components/equipmentServiceDialog.vue";

export default {
  computed: {
    DIALOG_TITLE_TYPE() {
      return DIALOG_TITLE_TYPE;
    },
    formatApiDate() {
      return formatApiDate;
    },
    UPLOAD_IMG_TYPE() {
      return UPLOAD_IMG_TYPE;
    },
    INTLLIGENT_STATUS() {
      return INTLLIGENT_STATUS;
    },
    INTLLIGENT_CONTROL_PLATFORM() {
      return INTLLIGENT_CONTROL_PLATFORM;
    },
    SUBDEVICE_CATEGORY_TYPE() {
      return SUBDEVICE_CATEGORY_TYPE;
    },
    SUBDEVICE_CATEGORY_TYPE_IMG() {
      return SUBDEVICE_CATEGORY_TYPE_IMG;
    },
    IS_ONLINE() {
      return IS_ONLINE;
    },
    getValue() {
      return getValue;
    }
  },
  components: { CascadeRegion, UploadList, EquipmentServiceDialog },
  filters: {},
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        deviceNo: "",
        typeId: "",
        storeId: "",
        controlPlatform: "",
        platformKey: "",
        provinceId: "",
        cityId: "",
        areaId: "",
        streetId: "",
        status: ""
      },
      dialogFormVisible: false, // 新增 or 编辑的弹框
      dialogStatus: "",
      rules: {
        typeId: [
          { required: true, message: "设备不能为空", trigger: "change" }
        ],
        controlPlatform: [
          { required: true, message: "控制平台不能为空", trigger: "change" }
        ],
        platformKey: [
          { required: true, message: "控制平台设备不能为空", trigger: "change" }
        ]
      },
      form: {
        id: "",
        typeId: "",
        controlPlatform: "",
        platformKey: "",
        storeId: "",
        imgs: [],
        introduction: ""
      },
      btnLoading: false,
      editObj: {}, // 修改的对象
      storeOption: [], // 门店选项
      equipmentOption: [], // 设备类别选项
      QRCodeImg: "", // 小程序显示的二维码图
      QRCodeImgLoading: false,
      equipmentServiceVisible: false, // 设备服务的弹框
      equipmentServiceObj: {}, // 设备服务的弹框
      synEquipmentLoading: false, // 同步设备
      subdeviceLoading: false, // 子设备的加载框
      subdeviceVisible: false, // 子设备的弹框
      subdeviceList: [], // 子设备的列表数据
      subdeviceObj: {}, // 子设备的添加数据
      subdeviceListQuery: {
        page: 1,
        limit: 10,
        category: "",
        platformKey: ""
      },
      subdeviceTotal: 0,
      deleteSubdeviceLoading: false,
      addSubdeviceLoading: false,
      deleteListSubdeviceLoading: false,
      codeVisible: false, // 查询二维码的页面
      moveInfoVisible: false, // 更多设备信息的页面
      moveInfoObj: {} // 更多设备信息的页面信息
    };
  },
  created() {
    this.getList();
    this.getStoreOption();
    this.getEquipmentOption();
  },
  watch: {},
  methods: {
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    subdeviceHandleFilter() {
      this.subdeviceListQuery.page = 1;
      this.getSubList();
    },
    subdeviceHandleSizeChange(val) {
      this.subdeviceListQuery.limit = val;
      this.getSubList();
    },
    subdeviceHandleCurrentChange(val) {
      this.subdeviceListQuery.page = val;
      this.getSubList();
    },
    /**
     * 查询用户列表
     */
    getList() {
      this.listLoading = true;
      const form = this.listQuery;
      const params = {
        ...form
      };
      getDeviceList(params)
        .then(response => {
          this.list = response.data.data;
          this.total = response.data.total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },

    /**
     * 查询门店
     */
    getEquipmentOption() {
      const params = {
        page: 1,
        limit: 9999
      };
      getEquipmentList(params).then(resp => {
        let equipmentOption = [];
        if (resp && resp.data) {
          equipmentOption = resp.data.data;
        }
        this.equipmentOption = equipmentOption;
      });
    },

    /**
     * 查询门店
     */
    getStoreOption() {
      const params = {
        page: 1,
        limit: 9999
      };
      getStoreList(params).then(resp => {
        let storeOption = [];
        if (resp && resp.data) {
          storeOption = resp.data.data;
        }
        this.storeOption = storeOption;
      });
    },

    /**
     * 切换状态
     * @param status
     * @param editObj
     */
    checkDialogFormVisible(status, editObj = {}) {
      this.dialogFormVisible = status;
      this.editObj = editObj;
      this.dialogStatus =
        editObj && Object.keys(editObj).length !== 0 ? "UPDATE" : "CREATE";
      if (editObj && Object.keys(editObj).length !== 0) {
        this.form.id = editObj.id;
        this.form.typeId = editObj.type_id;
        this.form.controlPlatform = editObj.control_platform;
        this.form.platformKey = editObj.platform_key;
        this.form.storeId = editObj.store_id;
        this.form.introduction = editObj.introduction;
        if (editObj.imgs) {
          const attDataArr = [];
          const imgs = editObj.imgs;
          attDataArr.push({
            dbUrl: imgs.url,
            url: imgs.show_url,
            name: getFileName(imgs.url)
          });
          this.form.imgs = attDataArr;
        }
      } else {
        this.form.id = "";
        this.form.category = "";
        this.form.introduction = "";
        this.form.name = "";
        this.form.imgs = [];
        this.$refs.form.resetFields();
      }
    },

    /**
     * 确认添加还是编辑
     */
    saveData() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          const form = this.form;
          const params = {
            ...form
          };
          if (form.imgs && form.imgs.length !== 0) {
            const urlArr = form.imgs.map(obj => obj["dbUrl"]);
            params.imgs = urlArr.join(",");
          }
          this.btnLoading = true;
          createDevice(params)
            .then(() => {
              this.getList();
              this.checkDialogFormVisible(false, {});
              this.$notify({
                title: "成功",
                message: "保存成功",
                type: "success",
                duration: 2000
              });
            })
            .finally(() => {
              this.btnLoading = false;
            });
        }
      });
    },
    /**
     *  获取图片的值
     * @param type
     * @param value
     */
    getImgsValueFunc(type, value) {
      if (type === "imgs") {
        this.form.imgs = value;
      }
    },
    /**
     * 省市区街道的值获取
     * @param type
     * @param resultArr
     */
    getQueryValueFunc(type, resultArr) {
      if (type === "queryCascadeRegion") {
        this.listQuery.cascadeRegion = resultArr;
        if (resultArr && resultArr.length !== 0) {
          this.listQuery.provinceId = resultArr[0];
          this.listQuery.cityId = resultArr[1];
          this.listQuery.areaId = resultArr[2];
          this.listQuery.streetId = resultArr[3];
        } else {
          this.listQuery.provinceId = "";
          this.listQuery.cityId = "";
          this.listQuery.areaId = "";
          this.listQuery.streetId = "";
        }
      }
    },

    /**
     * 设备上线
     * @param row
     */
    goOnline(row) {
      const params = {
        id: row.id
      };
      goOnlineDevice(params).then(resp => {
        if (resp.code === 0) {
          this.$notify({
            title: "成功",
            message: resp.data,
            type: "success",
            duration: 2000
          });
        } else {
          this.$notify({
            title: "提示",
            message: resp.data,
            type: "warning",
            duration: 2000
          });
        }
        this.getList();
      });
    },
    /**
     * 获取小程序二维码
     * @param row
     */
    getQRCode(row) {
      this.QRCodeImgLoading = true;
      const params = {
        id: row.id
      };
      getWxQrcode(params)
        .then(resp => {
          if (resp.code === 0) {
            this.QRCodeImg = resp.data;
            console.log(" resp.data", resp.data);
          } else {
            this.$notify({
              title: "提示",
              message: "小程序二维码获取失败",
              type: "warning",
              duration: 2000
            });
          }
        })
        .finally(() => {
          this.QRCodeImgLoading = false;
        });
    },

    /**
     * 切换设备服务
     * @param status
     * @param editObj
     */
    checkEquipmentServiceVisible(status, editObj = {}) {
      this.equipmentServiceVisible = status;
      this.equipmentServiceObj = editObj;
    },
    /**
     * 同步设备
     */
    synEquipmentFun() {
      this.synEquipmentLoading = true;
      synchDevices({})
        .then(resp => {
          if (resp.code === 0) {
            this.$notify({
              title: "提示",
              message: "同步完成",
              type: "success",
              duration: 2000
            });
            this.getList();
          }
        })
        .finally(() => {
          this.synEquipmentLoading = false;
        });
    },

    /**
     * 添加子设备
     * @param val
     * @param row
     */
    checkSubdeviceVisible(val, row = {}) {
      this.subdeviceObj = row;
      if (val) {
        this.getSubList(row);
      } else {
        this.getList();
      }
      this.subdeviceVisible = val;
    },

    /**
     * 添加子设备的列表
     */
    getSubList() {
      const row = this.subdeviceObj;
      const subdeviceListQuery = this.subdeviceListQuery;
      this.subdeviceLoading = true;
      const params = {
        ...subdeviceListQuery,
        id: row.id,
        controlPlatform: row.control_platform
      };
      getSubList(params)
        .then(resp => {
          this.subdeviceList = resp.data.data;
          this.subdeviceTotal = resp.data.total;
        })
        .finally(() => {
          this.subdeviceLoading = false;
        });
    },

    /**
     * 删除子设备
     */
    deleteSubdevice(row) {
      console.log("row", row);
      this.deleteSubdeviceLoading = true;
      const params = {
        subId: row.id
      };
      deleteSub(params)
        .then(resp => {
          if (resp.code === 0) {
            this.$notify({
              title: "温馨提示",
              message: resp.data,
              type: "success",
              duration: 2000
            });
            this.getSubList();
          }
        })
        .finally(() => {
          this.deleteSubdeviceLoading = false;
        });
    },
    /**
     * 添加子设备的绑定
     */
    addSubdevice(row) {
      this.addSubdeviceLoading = true;
      const subdeviceObj = this.subdeviceObj;

      const params = {
        id: subdeviceObj.id,
        subId: row.id
      };
      addSub(params)
        .then(resp => {
          if (resp.code === 0) {
            this.$notify({
              title: "温馨提示",
              message: resp.data,
              type: "success",
              duration: 2000
            });
            this.getSubList();
          }
        })
        .finally(() => {
          this.addSubdeviceLoading = false;
        });
    },
    /**
     * 删除列表子设备
     */
    deleteListSubdevice(row) {
      this.deleteListSubdeviceLoading = true;
      const params = {
        subId: row.id
      };
      deleteSub(params)
        .then(resp => {
          if (resp.code === 0) {
            this.$notify({
              title: "温馨提示",
              message: resp.data,
              type: "success",
              duration: 2000
            });
            this.getList();
          }
        })
        .finally(() => {
          this.deleteListSubdeviceLoading = false;
        });
    },
    /**
     * 选择下拉菜单
     */
    deviceHandleCommand(command, item) {
      if (command === "edit") {
        this.checkDialogFormVisible(true, item);
      } else if (command === "online") {
        this.goOnline(item);
      } else if (command === "code") {
        this.getQRCode(item);
        this.codeVisible = true;
      } else if (command === "service") {
        this.checkEquipmentServiceVisible(true, item);
      } else if (command === "addSubdevice") {
        this.checkSubdeviceVisible(true, item);
      }
    },

    checkMoveInfoVisible(val, row = {}) {
      console.log("val, row", val, row);
      this.moveInfoVisible = val;
      this.moveInfoObj = row;
    }
  }
};
</script>

<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="listQuery.deviceNo"
        placeholder="请输入设备编号"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-select
        v-model="listQuery.typeId"
        placeholder="设备类别"
        style="width: 120px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in equipmentOption"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
      <el-select
        v-model="listQuery.storeId"
        placeholder="门店"
        style="width: 120px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in storeOption"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>

      <el-select
        v-model="listQuery.controlPlatform"
        placeholder="控制平台"
        style="width: 120px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in INTLLIGENT_CONTROL_PLATFORM"
          :key="item.key"
          :label="item.value"
          :value="item.key"
        />
      </el-select>
      <el-input
        v-model="listQuery.platformKey"
        placeholder="请输入平台设备编号"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <CascadeRegion
        :isRequired="true"
        :value="listQuery.cascadeRegion"
        @valueFunc="getQueryValueFunc"
        type="queryCascadeRegion"
        :styleRef="{ width: '260px', marginRight: '6px', top: '-4px' }"
      />

      <el-select
        v-model="listQuery.status"
        placeholder="状态"
        style="width: 120px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in INTLLIGENT_STATUS"
          :key="item.key"
          :label="item.value"
          :value="item.key"
        />
      </el-select>
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
        >搜索</el-button
      >
      <!--      <el-button-->
      <!--        class="filter-item"-->
      <!--        type="primary"-->
      <!--        @click="checkDialogFormVisible(true, {})"-->
      <!--        >新增</el-button-->
      <!--      >-->
      <el-button
        :loading="synEquipmentLoading"
        class="filter-item"
        type="primary"
        @click="synEquipmentFun"
        >同步设备</el-button
      >
    </div>

    <div style="margin-bottom: 5px">智能设备数量:{{ total }}</div>

    <el-row
      v-loading="listLoading || deleteListSubdeviceLoading"
      :gutter="20"
      class="device-warp"
    >
      <el-col
        :span="3"
        v-for="item in list"
        :key="item.id"
        class="device-item-warp"
      >
        <el-card
          :body-style="{ padding: '0px' }"
        >
          <el-image
            :src="item.showImgs.show_url"
            style="width: 100%;height: 268px"
            fit="cover"
          />
          <div style="padding: 14px;">
            <div class="category-warp">
              <el-tag
                v-for="chItem in item.children"
                :key="chItem.id"
                closable
                :type="chItem.is_online === 1 ? '' : 'info'"
                size="mini"
                style="margin: 0 8px 8px 0"
                @close="deleteListSubdevice(chItem)"
              >
                {{ getValue(SUBDEVICE_CATEGORY_TYPE, chItem.category) }}
              </el-tag>
              <el-button
                class="button-new-tag"
                size="small"
                @click="checkSubdeviceVisible(true, item)"
                >+ 添加子设备</el-button
              >
            </div>
            <el-divider></el-divider>
            <div class="device-bottom">
              <span
                class="move-info-warp"
                @click="checkMoveInfoVisible(true, item)"
                >详情</span
              >
              <el-dropdown
                @command="command => deviceHandleCommand(command, item)"
              >
                <span class="el-dropdown-link">
                  操作<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="edit">编辑</el-dropdown-item>
                  <el-dropdown-item
                    command="online"
                    :disabled="[2, 3, 4].includes(item.status)"
                    >设备上线</el-dropdown-item
                  >
                  <el-dropdown-item command="code">
                    小程序二维码
                  </el-dropdown-item>
                  <el-dropdown-item command="service"
                    >设备服务</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <div :class="item.is_online ? 'online-warp':'online-warp online-no-warp'">
            {{getValue(IS_ONLINE,item.is_online)}}
          </div>
        </el-card>
      </el-col>
    </el-row>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!--    编辑和新增-->
    <el-dialog
      :title="DIALOG_TITLE_TYPE[dialogStatus]"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-position="left"
        label-width="150px"
        style="width: 80%; margin-left:50px;"
      >
        <el-form-item label="设备类别" prop="typeId" v-if="false">
          <el-select
            v-model="form.typeId"
            placeholder="请选择设备类别"
            style="width: 100%"
            class="filter-item"
            clearable
          >
            <el-option
              v-for="item in equipmentOption"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="门店" prop="storeId">
          <el-select
            v-model="form.storeId"
            placeholder="请选择门店"
            style="width: 100%"
            class="filter-item"
            clearable
          >
            <el-option
              v-for="item in storeOption"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="控制平台" prop="controlPlatform" v-if="false">
          <el-select
            v-model="form.controlPlatform"
            placeholder="请选择控制平台"
            style="width: 100%"
            class="filter-item"
            clearable
          >
            <el-option
              v-for="item in INTLLIGENT_CONTROL_PLATFORM"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="控制平台设备编号"
          prop="controlPlatform"
          v-if="false"
        >
          <el-input
            v-model="form.platformKey"
            placeholder="请输入设备编号"
            style="width: 100%"
            class="filter-item"
            clearable
            disabled
          />
        </el-form-item>
        <el-form-item label="图片" prop="name" v-if="false">
          <UploadList
            :typeProps="UPLOAD_IMG_TYPE.DEVICE"
            @valueFunc="getImgsValueFunc"
            paramsType="imgs"
            :model="form.imgs"
            :maxCount="1"
          />
        </el-form-item>
        <el-form-item label="简介" prop="introduction" v-if="false">
          <el-input
            type="textarea"
            v-model="form.introduction"
            placeholder="请输入"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="checkDialogFormVisible(false, {})">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>

    <!--    设备服务-->
    <el-dialog
      title="设备服务"
      :visible.sync="equipmentServiceVisible"
      :close-on-click-modal="false"
      width="50%"
    >
      <EquipmentServiceDialog :deviceInfoRef="equipmentServiceObj" />
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="checkEquipmentServiceVisible(false, {})"
          :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>

    <!--    添加子设备-->
    <el-dialog
      title="添加子设备"
      :visible.sync="subdeviceVisible"
      :close-on-click-modal="false"
      width="50%"
    >
      <div class="filter-container">
        <el-input
          v-model="subdeviceListQuery.platformKey"
          placeholder="请输入控制平台编号"
          style="width: 200px"
          class="filter-item"
          clearable
        />
        <el-select
          v-model="subdeviceListQuery.category"
          placeholder="设备类别"
          style="width: 120px"
          class="filter-item"
          clearable
        >
          <el-option
            v-for="item in SUBDEVICE_CATEGORY_TYPE"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          />
        </el-select>
        <el-button
          class="filter-item"
          type="primary"
          icon="el-icon-search"
          @click="subdeviceHandleFilter"
          >搜索</el-button
        >
      </div>
      <el-table
        v-loading="
          subdeviceLoading || deleteSubdeviceLoading || addSubdeviceLoading
        "
        :data="subdeviceList"
        element-loading-text="Loading"
        size="size"
        border
        highlight-current-row
      >
        <el-table-column align="center" prop="id" label="ID"></el-table-column>
        <el-table-column
          label="设备Id"
          align="center"
          prop="platform_key"
        ></el-table-column>
        <el-table-column label="设备名称" align="center" prop="name">
          <template slot-scope="scope">
            {{ getValue(SUBDEVICE_CATEGORY_TYPE, scope.row.type.category) }}
          </template>
        </el-table-column>
        <el-table-column prop="imgs" label="图片" align="center">
          <template slot-scope="scope">
            <el-image
              :src="
                getValue(SUBDEVICE_CATEGORY_TYPE_IMG, scope.row.type.category)
              "
              style="width: 40px"
              fit="cover"
            />
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
        >
          <template slot-scope="scope">
            <el-button
              type="danger"
              size="mini"
              @click="deleteSubdevice(scope.row)"
              v-show="scope.row.parent_id"
              >删除</el-button
            >
            <el-button
              type="success"
              size="mini"
              @click="addSubdevice(scope.row)"
              v-show="scope.row.parent_id === 0"
              style="margin-left: 0"
              >绑定</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="subdevice-pagination">
        <el-pagination
          :current-page="subdeviceListQuery.page"
          :page-sizes="[10, 20, 30, 50, 100]"
          :page-size="subdeviceListQuery.limit"
          :total="subdeviceTotal"
          background
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="subdeviceHandleSizeChange"
          @current-change="subdeviceHandleCurrentChange"
        />
      </div>

      <div class="dialog-footer">
        <el-button
          type="primary"
          @click="checkSubdeviceVisible(false, {})"
          :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>

    <!--    二维码-->
    <el-dialog
      title="小程序二维码"
      :visible.sync="codeVisible"
      :close-on-click-modal="false"
      width="500px"
      style="text-align: center"
    >
      <el-skeleton :loading="QRCodeImgLoading" animated>
        <el-image :src="QRCodeImg" />
      </el-skeleton>
      <div class="dialog-footer">
        <el-button type="primary" @click="codeVisible = false">确定</el-button>
      </div>
    </el-dialog>
    <!--    更多设备信息显示 -->
    <el-dialog
      title="设备详情"
      :visible.sync="moveInfoVisible"
      :close-on-click-modal="false"
      width="500px"
    >
      <div class="content">
        <div>
          <span class="content-title">设备编号:</span
          >{{
            moveInfoObj &&
              Object.keys(moveInfoObj).length !== 0 &&
              moveInfoObj.devices_num
          }}
        </div>
        <div>
          <span class="content-title">设备类别:</span
          >{{
            moveInfoObj &&
              Object.keys(moveInfoObj).length !== 0 &&
              moveInfoObj.type &&
              moveInfoObj.type.name
          }}
        </div>
        <div>
          <span class="content-title">状态:</span
          >{{
            moveInfoObj &&
              Object.keys(moveInfoObj).length !== 0 &&
              getValue(INTLLIGENT_STATUS, moveInfoObj.status)
          }}
        </div>
        <div>
          <span class="content-title">门店:</span
          >{{
            moveInfoObj &&
              Object.keys(moveInfoObj).length !== 0 &&
              moveInfoObj.store &&
              moveInfoObj.store.name
          }}
        </div>
        <div>
          <span class="content-title">设备ID:</span
          >{{
            moveInfoObj &&
              Object.keys(moveInfoObj).length !== 0 &&
              moveInfoObj.platform_key
          }}
        </div>
        <div>
          <span class="content-title">省/市/区/街道:</span>
          {{
            moveInfoObj &&
            Object.keys(moveInfoObj).length !== 0 &&
            moveInfoObj.province
              ? moveInfoObj.province.name
              : "无"
          }}
          /
          {{
            moveInfoObj &&
            Object.keys(moveInfoObj).length !== 0 &&
            moveInfoObj.city
              ? moveInfoObj.city.name
              : "无"
          }}
          /
          {{
            moveInfoObj &&
            Object.keys(moveInfoObj).length !== 0 &&
            moveInfoObj.area
              ? moveInfoObj.area.name
              : "无"
          }}
          /
          {{
            moveInfoObj &&
            Object.keys(moveInfoObj).length !== 0 &&
            moveInfoObj.street
              ? moveInfoObj.street.name
              : "无"
          }}
        </div>
      </div>
      <div class="dialog-footer">
        <el-button type="primary" @click="checkMoveInfoVisible(false, {})"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<style scoped lang="scss">
.device-warp {
  margin-top: 20px;
  .device-item-warp {
    position: relative;
  }
  .online-warp{
    position: absolute;
    right: 11px;
    top: 0;
    background-color: #11b95ce3;
    color: #fff;
    padding: 4px 18px;
    border-radius: 0px 4px 0 8px;
    font-size: 14px;
  }
  .online-no-warp{
    background-color: #82848a;
  }
  .category-warp {
    height: 80px;
    overflow-y: auto;
  }
  .button-new-tag {
    height: 22px;
    line-height: 20px;
    padding: 0 10px;
  }
  .device-bottom {
    padding-bottom: 10px;
    float: right;
    cursor: pointer;
  }
  .move-info-warp {
    margin-right: 10px;
    font-size: 14px;
    color: #409eff;
    cursor: pointer;
  }
  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
}
.subdevice-pagination {
  text-align: right;
  padding: 20px 0;
}
.dialog-footer {
  text-align: right;
}

.content {
  margin-left: 10px;
  font-size: 16px;
  line-height: 30px;
}
.content-title {
  font-weight: 600;
  line-height: 26px;
  margin-right: 10px;
}
</style>
